// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatures-module--root--1GOzQ";
export var box__aEtmx = "PlasmicFeatures-module--box__aEtmx--3tV71";
export var header = "PlasmicFeatures-module--header--1Ld5D";
export var section = "PlasmicFeatures-module--section--AVnp2";
export var box___71F = "PlasmicFeatures-module--box___71F--3Q6Go";
export var box___2M5Wr = "PlasmicFeatures-module--box___2M5Wr--aOlkD";
export var box__t7WXc = "PlasmicFeatures-module--box__t7WXc--1ZL0J";
export var box__pX41D = "PlasmicFeatures-module--box__pX41D--3VG84";
export var bullet__a0J1O = "PlasmicFeatures-module--bullet__a0J1O--67dYD";
export var bullet__fImUg = "PlasmicFeatures-module--bullet__fImUg--2LJ7G";
export var bullet__oDuL8 = "PlasmicFeatures-module--bullet__oDuL8--1CShP";
export var box__axqcv = "PlasmicFeatures-module--box__axqcv--3TG33";
export var box__jTtrs = "PlasmicFeatures-module--box__jTtrs--4KcTm";
export var box__vCknE = "PlasmicFeatures-module--box__vCknE--3Iy-L";
export var img___4VioL = "PlasmicFeatures-module--img___4VioL--1KOKQ";
export var img__a56Y1 = "PlasmicFeatures-module--img__a56Y1--12wOr";
export var homeCta = "PlasmicFeatures-module--homeCta--3_pJJ";
export var footer = "PlasmicFeatures-module--footer--2Pg4E";